<template>
  <div v-html="toHtml()" />
</template>

<script>
import MarkdownIt from 'markdown-it'

const md = new MarkdownIt()

export default {
  name: 'MarkdownContent',
  props: {
    content: {
      type: String,
      default: ''
    }
  },
  methods: {
    toHtml () {
      if (md) {
        return md
          .render(this.content.replace(/\n{1,}/g, '\n\n'))
          .replace(/<li>\s*<p>/g, '<li>')
          .replace(/<\/p>\s*<\/li>/g, '</li>')
      }
    }
  }
}
</script>
